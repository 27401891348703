import "../Assets/Css/dashboard-style.css"
import React, { useEffect, useState } from 'react'

// import Metalogo from "../Assests/images/images-dash/meta-king-logo.png"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";

import unknownuser from "../Assets/images/UnknownUser.webp";
import bnbcoin from "../Assets/images/dusd.png";
import finalgif from "../Assets/images/final.gif";

import { FaCopy } from 'react-icons/fa';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { FaArrowUp } from 'react-icons/fa';
import { PiArrowSquareOutBold } from 'react-icons/pi';
import { ImArrowRight2, ImTab } from 'react-icons/im';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastApi from '../config/toastApi';
import config from '../config/apiConfig';
import Window_Ethereum_Config_Api from '../config/windowEthereumConfig';
import DUSDtokenAbi from '../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../ABI/Plan1Abi.json';
import DemoCorePlanAbi from '../ABI/DemoCorePlan.json';
import Web3 from 'web3';
import {
  showUserTransactionInDashBoard, getTheLevelOfCompletedTransactionsDetsils, getAllUserRegisterList, buyNewLevelApprovalStatus,
  buyNewNextLevelOfMLMId, getParentID, linkedIdsFromData, findBuyerAddressAndParentId, getTheRegistrationDmlmXcoreCompletedTransactionsDetsils, getTheBuyNextLevelDmlmXcoreCompletedTransactions, rewardForRankIncome, getallpartnerdetails,
  showUserOwnerIdDetails,
} from "../Dashboard-pages/api/authapis";
import { formatDistanceToNow } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router-dom'; // Import withRouter

import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import axios from "axios";
import { Tooltip } from 'react-tooltip';

function App() {
  const [listFind, setListFind] = useState([]);
  const [connectedWallet, setConnectedWallet] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [referralAddress, setReferralAddress] = useState();
  const [referredByUserId, setReferredByUserId] = useState();
  const [registerDataList, setRegisterDataList] = useState([]);
  const [invitedDate, setInvitedDate] = useState(null);
  const [thePartnersLength, setThePartnersLength] = useState(0);
  const [teamMemberLength, setTeamMemberLength] = useState([0]);
  const [returnEarnedPercentage, setReturnEarnedPercentage] = useState(0);
  const [visibleItems, setVisibleItems] = useState(config.REGISTER_HISTORY_SEE_MORE_LESS);
  const [isXcoreContractAddressCopied, setIsXcoreContractAddressCopied] = useState(false);
  const [isLevelIncomeContractAddressCopied, setIsLevelIncomeContractAddressCopied] = useState(false);
  const [copyPathNameOfCurrentLocation, setCopyPathNameOfCurrentLocation] = useState(false);
  const [pathNameOfCurrentLocation, setPathNameOfCurrentLocation] = useState();
  const [registrationPageUrl, setRegistrationPageUrl] = useState();
  const [checkLevelOfXcore, setCheckLevelOfXcore] = useState(0);
  const [dmlmXxxxGoldPageLink, setDmlmXxxxGoldPageLink] = useState();
  const [contractFunds, setContractFunds] = useState(0);
  const [transactionDetailsInTable, setTransactionDetailsInTable] = useState([0]);


  const [checkLevelMatching, setCheckLevelMatching] = useState();

  const [partnerFirstLoopLength, setPartnerFirstLoopLength] = useState(0);
  const [partnerSecondLoopLength, setPartnerSecondLoopLength] = useState(0);
  const [partnerThirdLoopLength, setPartnerThirdLoopLength] = useState(0);
  const [partnerForthLoopLength, setPartnerForthLoopLength] = useState(0);
  const [partnerFifthLoopLength, setPartnerFifthLoopLength] = useState(0);
  const [partnerSixthLoopLength, setPartnerSixthLoopLength] = useState(0);
  const [partnerSevenLoopLength, setPartnerSevenLoopLength] = useState(0);
  const [partnerEightLoopLength, setPartnerEightLoopLength] = useState(0);
  const [partnerNineLoopLength, setPartnerNineLoopLength] = useState(0);
  const [partnerTenLoopLength, setPartnerTenLoopLength] = useState(0);
  const [referralWalletAddress, setReferralWalletAddress] = useState();

  const [firstLevelStatus, setFirstLevelStatus] = useState(false);
  const [secondLevelStatus, setSecondLevelStatus] = useState(false);
  const [thirdLevelStatus, setThirdLevelStatus] = useState(false);
  const [forthLevelStatus, setForthLevelStatus] = useState(false);
  const [fifthLevelStatus, setFifthLevelStatus] = useState(false);
  const [sixthLevelStatus, setSixthLevelStatus] = useState(false);
  const [sevenLevelStatus, setSevenLevelStatus] = useState(false);
  const [egithLevelStatus, setEigthLevelStatus] = useState(false);
  const [nineLevelStatus, setNineLevelStatus] = useState(false);
  const [tenLevelStatus, setTenLevelStatus] = useState(false);

  const [getFirstCycles, setGetFristCycles] = useState(0);
  const [totalRankRewardAmount, setTotalRankRewardAmount] = useState(0);
  const [RewardAmount, setRewardAmount] = useState([]);
  const [xCoreAmountFromTheUser, setxCoreAmountFromTheUser] = useState(0);
  const [levelIncomeAmountFromTheUser, setLevelIncomeAmountFromTheUser] = useState(0);
  const [partnetlen, setPartnetlen] = useState([])
  const [teamlen, setTeamlen] = useState([])
  const toastCheck = React.useRef(null);


  var web3js = '';

  if (window.ethereum) {
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  } else {
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  }
  const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
  const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);
  const xCoreContract = new web3js.eth.Contract(Plan1Abi, config.PLAN_1_CONTRACT_ADDRESS);

  const locateUserId = window.location.pathname.split('/')[2];




  const getTheUserWalletAddress = async () => {
    try {

      const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
      setReferralAddress(checkUserRegisteredExists);



      const checkReferralUserDetails = await DemoCorePlanContract.methods.users(checkUserRegisteredExists).call();

      const checkReferralIDWithAddressUserDetails = await DemoCorePlanContract.methods.users(checkReferralUserDetails.referrer).call();
      const objAddress = await convertBigIntToString(checkReferralIDWithAddressUserDetails);

      setReferredByUserId(objAddress.id);
      const xCoreAmount = await DemoCorePlanContract.methods.userxcoreincome(checkUserRegisteredExists).call();
      const levelIncomeAmount = await xCoreContract.methods.userlevelincome(checkUserRegisteredExists).call();

      const xCorefromWeiAmount = web3js.utils.fromWei(xCoreAmount, 'ether');
      const levelIncomeFromWeiAmount = web3js.utils.fromWei(levelIncomeAmount, 'ether');

      if (xCorefromWeiAmount && levelIncomeFromWeiAmount) {
        setxCoreAmountFromTheUser(xCorefromWeiAmount);
        setLevelIncomeAmountFromTheUser(levelIncomeFromWeiAmount);
        getRatioAnalysisData(xCoreAmountFromTheUser, levelIncomeAmountFromTheUser)
      } else {
        setxCoreAmountFromTheUser(0);
        setLevelIncomeAmountFromTheUser(0);
      }

    } catch (error) {
      console.error('Error in getTheUserWalletAddress:', error);
    }
  };

  const convertBigIntToString = async (obj) => {
    if (typeof obj === 'bigint') {
      return obj.toString();
    } else if (typeof obj === 'object') {
      for (const key in obj) {
        obj[key] = await convertBigIntToString(obj[key]);
      }
    }

    return obj;
  };

  // Call the async function
  getTheUserWalletAddress();



  const sliceAddress = (address, startLength = 6, endLength = 6) => {
    try {
      if (!address) return '';

      const prefix = address.slice(0, startLength);
      const suffix = address.slice(-endLength);

      return `${prefix}...${suffix}`;
    } catch (error) {
      return '';
    }
  };

  const handleXcoreContractAddressCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
      setIsXcoreContractAddressCopied(true);
      toast.success(ToastApi.ContractAddressCopied, { autoClose: 2000 });
    } catch (error) {
      toast.error(ToastApi.FailedToCopy);
    }
  };

  const handleLevelIncomeContractAddressCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(config.PLAN_1_CONTRACT_ADDRESS);
      setIsLevelIncomeContractAddressCopied(true);
      toast.success(ToastApi.ContractAddressCopied, { autoClose: 2000 });
    } catch (error) {
      toast.error(ToastApi.FailedToCopy);
    }
  };


  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(referralAddress);
      setIsCopied(true);
      toast.success(ToastApi.CopiedAddress, { autoClose: 2000 });
    } catch (error) {
      toast.error(ToastApi.FailedToCopy);
    }
  };


  const formatHistorydate = (data) => {
    const dateObject = new Date(data);
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Use 24-hour format
      timeZone: "UTC", // Set time zone to UTC
    };
    const formatted = dateObject.toLocaleDateString("en-US", options);
    return formatted;
  };

  const getListOfRegisterData = async () => {
    try {


      const modifiedDataArray = [];
      const referralAddressesSet = [];

      const userid = locateUserId
      const resultofownerid = await showUserOwnerIdDetails(userid)

      let registerdatauserid = resultofownerid.data.data.New_User_Registered_Address
      let usercreatedata = resultofownerid.data.data.Create_At


      const checkUserRegisteredExist = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();

      if (checkUserRegisteredExist === registerdatauserid) {
        const Date = formatHistorydate(usercreatedata)
        setInvitedDate(Date);
      }

      // const resultOfRegisterData = await showUserTransactionInDashBoard();
      // for (const registerDataItem of resultOfRegisterData.data.data) {
      //   const createdAtDate = new Date(registerDataItem.Create_At);

      //   const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();

      //   console.log("registerDataItem",registerDataItem.New_User_Registered_Address , "checkUserRegisteredExists",checkUserRegisteredExists);

      //   const checkReferralUserDetails = await DemoCorePlanContract.methods.users(checkUserRegisteredExists).call();

      //   const checkReferralIDWithAddressUserDetails = await DemoCorePlanContract.methods.users(checkReferralUserDetails.referrer).call();
      //   const objAddress = await convertBigIntToString(checkReferralIDWithAddressUserDetails);


      //   const checkTheNewUserRefferIdAndOldOne = registerDataItem.Referral_ID == locateUserId;


      //   if (checkTheNewUserRefferIdAndOldOne) {
      //     const directReferral = await getTeamAnalysisData(registerDataItem.Referral_Address);
      //     referralAddressesSet.push(directReferral);

      //   }

      //   if (checkUserRegisteredExists === registerDataItem.New_User_Registered_Address) {
      //     const date = await formatHistorydate(registerDataItem.Create_At)
      //     console.log("date---utc", date)

      //     // setInvitedDate(date);
      //   }
      //   // Check if the user matches the condition
      //   if (registerDataItem.Referral_ID == objAddress.id) {
      //     const formattedDate = new Intl.DateTimeFormat('en-US', {
      //       year: 'numeric',
      //       month: 'short',
      //       day: '2-digit',
      //       hour: '2-digit',
      //       minute: '2-digit',
      //       second: '2-digit'
      //     }).format(createdAtDate);
      //     // setInvitedDate(formattedDate);

      //     // Add the item to the modifiedDataArray if needed
      //     modifiedDataArray.push({ ...registerDataItem, formattedDate });
      //   }
      // }


      const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();

      const findTheGroupOfDirectReferredID = await DemoCorePlanContract.methods.directreferral(checkUserRegisteredExists).call();
      setThePartnersLength(findTheGroupOfDirectReferredID.length);

      const findTheGroupOfIndirectReferredID = await DemoCorePlanContract.methods.getindirectref(checkUserRegisteredExists).call();

      setTeamMemberLength(findTheGroupOfIndirectReferredID.length);

      const XcoreAndLevelContractTotAmount = await DemoCorePlanContract.methods.XcoreAndLevelContractTotAmount().call();
      const fromWeiAmount = web3js.utils.fromWei(XcoreAndLevelContractTotAmount, 'ether');

      setContractFunds(fromWeiAmount);
    } catch (error) {
      console.error('Error fetching register data:', error);
    }

    // try {
    //   const resultOfRegisterData = await showUserTransactionInDashBoard();
    //   const modifiedDataArray = [];

    //   for (const registerDataItem of resultOfRegisterData.data.data) {
    //     // console.log('resultOfRegisterData---', registerDataItem);

    //     // Convert the Create_At timestamp to a JavaScript Date object
    //     const createdAtDate = new Date(registerDataItem.Create_At);

    //     const newItem = {
    //       newUserReferralId: registerDataItem.New_User_Referral_Id,
    //       newUserRegisteredAddress: registerDataItem.New_User_Registered_Address,
    //       referralAddress: registerDataItem.Referral_Address,
    //       referralId: registerDataItem.Referral_ID,
    //       status: registerDataItem.status,
    //       txHash: registerDataItem.tx_Hash,
    //       dateTime: formatDistanceToNow(createdAtDate, { addSuffix: true }),
    //     };

    //     modifiedDataArray.push(newItem);
    //   }

    //   setRegisterDataList(modifiedDataArray);
    // } catch (error) {
    //   console.error('Error fetching register data:', error);
    // }

  };

  const getListOfRegisterData1 = async () => {
    try {
      const resultOfRegisterData = await showUserTransactionInDashBoard();

      const modifiedDataArray = [];

      for (const registerDataItem of resultOfRegisterData.data.data) {


        // Convert the Create_At timestamp to a JavaScript Date object
        const createdAtDate = new Date(registerDataItem.Create_At);

        const newItem = {
          newUserReferralId: registerDataItem.New_User_Referral_Id,
          newUserRegisteredAddress: registerDataItem.New_User_Registered_Address,
          referralAddress: registerDataItem.Referral_Address,
          referralId: registerDataItem.Referral_ID,
          status: registerDataItem.status,
          txHash: registerDataItem.tx_Hash,
          dateTime: formatDistanceToNow(createdAtDate, { addSuffix: true }),
        };

        modifiedDataArray.push(newItem);
      }

      setRegisterDataList(modifiedDataArray);
    } catch (error) {
      console.error('Error fetching register data:', error);
    }
  }


  // Helper function to get direct referral details
  const getDirectReferralDetails = async (referralDirectAddress) => {
    const details = await DemoCorePlanContract.methods.directreferral(referralDirectAddress).call();
    return details;
  };

  const getTeamAnalysisData = async (referralTeamAddress) => {
    try {
      // Adjust this line to use the correct method
      const details = await DemoCorePlanContract.methods.directreferral(referralTeamAddress).call();

      return details;
    } catch (error) {
      console.error('Error fetching team analysis data:', error);
      throw error; // Propagate the error for better error handling
    }
  };
  const totalProfitAmount = parseFloat(xCoreAmountFromTheUser) + parseFloat(levelIncomeAmountFromTheUser);


  const getRatioAnalysisData = async (xcore, levelincome) => {
    try {
      const tp = Number(xcore) + Number(levelincome);

      // Retrieve user's address and level
      const checkRewardUserAddress = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();
      const checkRewardUserLevels = await DemoCorePlanContract.methods.getlevel(checkRewardUserAddress).call();
      const level = parseInt(checkRewardUserLevels); // Convert level to integer

      let profitShare = 0;

      // Calculate profit share based on user's level using if-else statements
      if (level === 1) {
        profitShare = tp / 15;
      } else if (level === 2) {

        profitShare = tp / 45;
      } else if (level === 3) {

        profitShare = tp / 145;
      } else if (level === 4) {

        profitShare = tp / 345;
      } else if (level === 5) {

        profitShare = tp / 645;
      } else if (level === 6) {

        profitShare = tp / 1145;
      } else if (level === 7) {

        profitShare = tp / 2145;
      } else if (level === 8) {

        profitShare = tp / 3645;
      } else if (level === 9) {

        profitShare = tp / 6145;
      } else if (level === 10) {
        profitShare = tp / 11145;


      } else {
        console.log('Invalid level.');
      }



      // Handle NaN or infinite profitShare values
      const returnEarnedPercentage = isNaN(profitShare) || !isFinite(profitShare) ? 0 : profitShare;


      // Set the calculated profit share percentage in state or perform further processing
      setReturnEarnedPercentage((returnEarnedPercentage * 100).toFixed(1));

    } catch (error) {
      console.error('Error fetching ratio analysis data:', error);
      // Handle or propagate the error as needed
      throw error;
    }
  };





  const handleSeeMoreClick = () => {
    // Increase the number of visible items (you can adjust the step as needed)
    setVisibleItems((prevVisibleItems) => prevVisibleItems + config.REGISTER_HISTORY_SEE_MORE_LESS);
  };

  const handleSeeLessClick = () => {
    // Decrease the number of visible items (you can adjust the step as needed)
    setVisibleItems((prevVisibleItems) => Math.max(prevVisibleItems - config.REGISTER_HISTORY_SEE_MORE_LESS, config.REGISTER_HISTORY_SEE_MORE_LESS));
  };

  const getRegisterationLinkWithID = async () => {
    try {

      const currentLocation = window.location.pathname;
      const registerLink = config.REGISTRATION_PAGE_URL + locateUserId;
      //  const registerLink = config.REGISTRATION_PAGE_URL
      setPathNameOfCurrentLocation(registerLink);
      await navigator.clipboard.writeText(registerLink);
      setCopyPathNameOfCurrentLocation(true);
      toast.success(ToastApi.RegisterLinkCopiedWithID, { autoClose: 2000 });
    } catch (error) {
      toast.error(ToastApi.FailedToCopy);
    }
  }



  useEffect(() => {
    // getRatioAnalysisData();
    const currentLocation = window.location.pathname;
    const registrationPageLink = config.REGISTRATION_PAGE_URL + locateUserId;

    // const registerLink = config.REGISTRATION_PAGE_URL + locateUserId;
    setPathNameOfCurrentLocation(registrationPageLink);
    setRegistrationPageUrl(registrationPageLink);
    const dMlmXxxxGoldPage = config.WINDOW_LOCATION_DMLMXXXXGOLD_PAGE + locateUserId;
    setDmlmXxxxGoldPageLink(dMlmXxxxGoldPage);
    // transactionDetailsWithPagination();
  }, []);

  const checkConnectedWallet = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
        setConnectedWallet(accounts?.[0] || null);
      }
    } catch (error) {
      console.error('Error checking connected wallet:', error);
    }
  };

  const Rewardranking = async () => {
    try {
      const data = await rewardForRankIncome(locateUserId);

      var rawardAmountArray = []
      data.result.Reward_Amount.forEach(element => {
        const elementObject = {};
        elementObject['rank_income_reward'] = element;
        rawardAmountArray.push(elementObject);
      });

      let count = 0;
      rawardAmountArray.forEach((ele) => {
        const amount = parseFloat(ele.rank_income_reward);
        count += amount;
      })

      if (count) {
        setTotalRankRewardAmount(count)
      } else {
        setTotalRankRewardAmount(0)
      }
      setRewardAmount(rawardAmountArray)
    } catch (error) {

    }
  }

  const calculateTotalAmount = () => {
    let sum = 0;

    RewardAmount.forEach(item => {
      const amount = parseFloat(item.rank_income_reward);
      // if (!isNaN(amount)) {
      sum += amount;
      // }
    });
    // console.log('sum----', sum);
    // setTotalRankRewardAmount(sum)

  };

  useEffect(() => {
    checkConnectedWallet();
    Rewardranking();
    calculateTotalAmount();
  }, [locateUserId, connectedWallet]);

  const getListOfRegisterAndBuyLevelData = async () => {
    try {


      const resultOfRegisterData = await getAllUserRegisterList(locateUserId);

      const arrayOfArray = [];
      arrayOfArray.push(resultOfRegisterData.data.data)
      for (const registerDataItem of arrayOfArray) {

        if (registerDataItem.New_User_Referral_Id == locateUserId) {

          var levelOfData = await checkNextNewLevelStatus();

          for (let i = 0; i < levelOfData.result.length; i++) {

            var elements = levelOfData.result[i].Buy_Level_Status;
            setCheckLevelMatching(elements)
          }

          const referralAddressesSet = [];
          const secondReferralAddressesSet = [];
          const thirdReferralAddressesSet = [];
          const forthReferralAddressesSet = [];
          const fifthReferralAddressesSet = [];
          const sixthReferralAddressesSet = [];
          const sevenReferralAddressesSet = [];
          const eightReferralAddressesSet = [];
          const nineReferralAddressesSet = [];
          const tenReferralAddressesSet = [];

          const checkUserRegisteredExists = await DemoCorePlanContract.methods.idToAddress(locateUserId).call();

          let FirstLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredExists, 1).call();
          setFirstLevelStatus(FirstLevelStatus);
          if (FirstLevelStatus) {


            const firstUserlevelsl = await DemoCorePlanContract.methods.getcycles(checkUserRegisteredExists, 1).call();
            // const firstUserlevel2 = await DemoCorePlanContract.methods.usersX6Matrix(checkUserRegisteredExists, 1, 0).call();
            // let checkTheUserLevel = 3 - firstUserlevelsl.toString();
            // console.log('firstUserlevelsl----', firstUserlevelsl);
            setGetFristCycles(firstUserlevelsl.toString());
            const findTheGroupOfReferredID = await DemoCorePlanContract.methods.directreferral(checkUserRegisteredExists).call();
            for (let i = 0; i < findTheGroupOfReferredID.length; i++) {
              const element = findTheGroupOfReferredID[i];
              const checkUserRegisteredExists = await DemoCorePlanContract.methods.users(element).call();

              referralAddressesSet.push(checkUserRegisteredExists.id.toString());
            }
            dmlmXcoreTransactionDetailsWithRegisteration(referralAddressesSet);

            setPartnerFirstLoopLength(referralAddressesSet.length);




          }
          let TwoLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredExists, 2).call();
          setSecondLevelStatus(TwoLevelStatus);
          if (TwoLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {
              if (levelOfData.result[i].Buy_Level_Status == 'Level-2') {
                var elements = levelOfData.result[i].Owner_MLM_ID;

                if (elements) {

                  secondReferralAddressesSet.push(elements);
                } else {


                  secondReferralAddressesSet.push(elements);
                }
              }

            }

            setPartnerSecondLoopLength(secondReferralAddressesSet.length);
            // secondReferralAddressesSet.forEach(async (element) => {
            dmlmXcoreBuyNextLevelTransactionDetails(secondReferralAddressesSet, 'Level-2');


          } else if (TwoLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {

              var elements = levelOfData.result[i].Owner_MLM_ID;
              if (elements) {

                secondReferralAddressesSet.push(elements);
              } else {


                secondReferralAddressesSet.push(elements);
              }


            }
            // console.log('secondReferralAddressesSet--sdfdsfdsf-', secondReferralAddressesSet);
            if (checkLevelMatching == 'Level-2') {
              setPartnerSecondLoopLength(secondReferralAddressesSet.length);
            }


          }

          let ThreeLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredExists, 3).call();
          setThirdLevelStatus(ThreeLevelStatus);
          if (ThreeLevelStatus) {
            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {
              if (levelOfData.result[i].Buy_Level_Status == 'Level-3') {
                var elements = levelOfData.result[i].Owner_MLM_ID;

                if (elements) {

                  thirdReferralAddressesSet.push(elements);
                } else {


                  thirdReferralAddressesSet.push(elements);
                }
              }

            }

            setPartnerThirdLoopLength(thirdReferralAddressesSet.length);
            dmlmXcoreBuyNextLevelTransactionDetails(thirdReferralAddressesSet, 'Level-3');



          } else if (ThreeLevelStatus) {
            for (let i = 0; i < levelOfData.result.length; i++) {
              var elements = levelOfData.result[i].Owner_MLM_ID;
              if (elements) {

                thirdReferralAddressesSet.push(elements);
              } else {


                thirdReferralAddressesSet.push(elements);
              }


            }
            if (checkLevelMatching == 'Level-3') {
              setPartnerThirdLoopLength(thirdReferralAddressesSet.length);
            }

          }

          let FourLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredExists, 4).call();
          setForthLevelStatus(FourLevelStatus);
          if (FourLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {
              if (levelOfData.result[i].Buy_Level_Status == 'Level-4') {
                var elements = levelOfData.result[i].Owner_MLM_ID;
                if (elements) {
                  forthReferralAddressesSet.push(elements);
                } else {
                  forthReferralAddressesSet.push(elements);
                }
              }
            }
            setPartnerForthLoopLength(forthReferralAddressesSet.length);
            dmlmXcoreBuyNextLevelTransactionDetails(forthReferralAddressesSet, 'Level-4');



          } else if (FourLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {

              var elements = levelOfData.result[i].Owner_MLM_ID;
              if (elements) {
                forthReferralAddressesSet.push(elements);
              } else {
                forthReferralAddressesSet.push(elements);
              }
            }
            if (checkLevelMatching == 'Level-4') {
              setPartnerForthLoopLength(forthReferralAddressesSet.length);
            }


          }


          let FiveLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredExists, 5).call();
          setFifthLevelStatus(FiveLevelStatus);

          if (FiveLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {
              if (levelOfData.result[i].Buy_Level_Status == 'Level-5') {
                var elements = levelOfData.result[i].Owner_MLM_ID;
                if (elements) {

                  fifthReferralAddressesSet.push(elements);
                } else {


                  fifthReferralAddressesSet.push(elements);
                }

              }
            }

            setPartnerFifthLoopLength(fifthReferralAddressesSet.length);
            dmlmXcoreBuyNextLevelTransactionDetails(fifthReferralAddressesSet, 'Level-5');



          } else if (FiveLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {

              var elements = levelOfData.result[i].Owner_MLM_ID;
              if (elements) {

                fifthReferralAddressesSet.push(elements);
              } else {


                fifthReferralAddressesSet.push(elements);
              }


            }
            if (checkLevelMatching == 'Level-5') {
              setPartnerFifthLoopLength(fifthReferralAddressesSet.length);
            }

          }

          let SixLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredExists, 6).call();
          setSixthLevelStatus(SixLevelStatus);

          if (SixLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {
              if (levelOfData.result[i].Buy_Level_Status == 'Level-6') {
                var elements = levelOfData.result[i].Owner_MLM_ID;
                if (elements) {

                  sixthReferralAddressesSet.push(elements);
                } else {


                  sixthReferralAddressesSet.push(elements);
                }
              }

            }

            setPartnerSixthLoopLength(sixthReferralAddressesSet.length);
            dmlmXcoreBuyNextLevelTransactionDetails(sixthReferralAddressesSet, 'Level-6');



          } else if (SixLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {

              var elements = levelOfData.result[i].Owner_MLM_ID;
              if (elements) {

                sixthReferralAddressesSet.push(elements);
              } else {


                sixthReferralAddressesSet.push(elements);
              }


            }
            if (checkLevelMatching == 'Level-6') {
              setPartnerSixthLoopLength(sixthReferralAddressesSet.length);
            }


          }

          let SevenLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredExists, 7).call();
          setSevenLevelStatus(SevenLevelStatus);

          if (SevenLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {
              if (levelOfData.result[i].Buy_Level_Status == 'Level-7') {
                var elements = levelOfData.result[i].Owner_MLM_ID;
                if (elements) {

                  sevenReferralAddressesSet.push(elements);
                } else {


                  sevenReferralAddressesSet.push(elements);
                }
              }

            }

            setPartnerSevenLoopLength(sevenReferralAddressesSet.length);
            dmlmXcoreBuyNextLevelTransactionDetails(sevenReferralAddressesSet, 'Level-7');



          } else if (SevenLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {

              var elements = levelOfData.result[i].Owner_MLM_ID;
              if (elements) {

                sevenReferralAddressesSet.push(elements);
              } else {


                sevenReferralAddressesSet.push(elements);
              }


            }
            if (checkLevelMatching == 'Level-7') {
              setPartnerSevenLoopLength(sevenReferralAddressesSet.length);
            }

          }

          let EightLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredExists, 8).call();
          setEigthLevelStatus(EightLevelStatus);

          if (EightLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {
              if (levelOfData.result[i].Buy_Level_Status == 'Level-8') {
                var elements = levelOfData.result[i].Owner_MLM_ID;
                if (elements) {

                  eightReferralAddressesSet.push(elements);
                } else {


                  eightReferralAddressesSet.push(elements);
                }

              }
            }

            setPartnerEightLoopLength(eightReferralAddressesSet.length);
            dmlmXcoreBuyNextLevelTransactionDetails(eightReferralAddressesSet, 'Level-8');



          } else if (EightLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {

              var elements = levelOfData.result[i].Owner_MLM_ID;
              if (elements) {

                eightReferralAddressesSet.push(elements);
              } else {


                eightReferralAddressesSet.push(elements);
              }


            }
            if (checkLevelMatching == 'Level-8') {

              setPartnerEightLoopLength(eightReferralAddressesSet.length);
            }



          }

          let NineLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredExists, 9).call();
          setNineLevelStatus(NineLevelStatus);

          if (NineLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {
              if (levelOfData.result[i].Buy_Level_Status == 'Level-9') {
                var elements = levelOfData.result[i].Owner_MLM_ID;
                if (elements) {

                  nineReferralAddressesSet.push(elements);
                } else {


                  nineReferralAddressesSet.push(elements);
                }

              }
            }

            setPartnerNineLoopLength(nineReferralAddressesSet.length);
            dmlmXcoreBuyNextLevelTransactionDetails(nineReferralAddressesSet, 'Level-9');



          } else if (NineLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {

              var elements = levelOfData.result[i].Owner_MLM_ID;
              if (elements) {

                nineReferralAddressesSet.push(elements);
              } else {


                nineReferralAddressesSet.push(elements);
              }


            }
            if (checkLevelMatching == 'Level-9') {
              setPartnerNineLoopLength(nineReferralAddressesSet.length);
            }


          }

          let TenLevelStatus = await DemoCorePlanContract.methods.usercompletelevel(checkUserRegisteredExists, 10).call();
          setTenLevelStatus(TenLevelStatus);

          if (TenLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {
              if (levelOfData.result[i].Buy_Level_Status == 'Level-10') {
                var elements = levelOfData.result[i].Owner_MLM_ID;
                if (elements) {

                  tenReferralAddressesSet.push(elements);
                } else {


                  tenReferralAddressesSet.push(elements);
                }
              }

            }

            setPartnerTenLoopLength(tenReferralAddressesSet.length);
            dmlmXcoreBuyNextLevelTransactionDetails(tenReferralAddressesSet, 'Level-10');


          } else if (TenLevelStatus) {

            var levelOfData = await checkNextNewLevelStatus();

            for (let i = 0; i < levelOfData.result.length; i++) {

              var elements = levelOfData.result[i].Owner_MLM_ID;
              if (elements) {

                tenReferralAddressesSet.push(elements);
              } else {


                tenReferralAddressesSet.push(elements);
              }


            }
            if (checkLevelMatching == 'Level-10') {
              setPartnerTenLoopLength(tenReferralAddressesSet.length);
            }


          }


        }
      }

    } catch (error) {
      console.error('Error fetching register data:', error);
    }


  };

  const checkNextNewLevelStatus = async () => {
    const nextLeveData = await getParentID(locateUserId);
    return nextLeveData;

  }

  useEffect(() => {
    getListOfRegisterAndBuyLevelData();

  }, []);

  const dmlmXcoreTransactionDetailsWithRegisteration = async (referralAddressesSet) => {
    const showTheTransactionDetails = await getTheRegistrationDmlmXcoreCompletedTransactionsDetsils(referralAddressesSet);

    const assignValue = [];
    showTheTransactionDetails.result.forEach(element => {

      const objectValue = {};
      var createdDate = new Date(element.Create_At);

      // // Format date
      var formattedDate = createdDate.getDate() + " " +
        createdDate.toLocaleString('default', { month: 'short' }) + " " +
        createdDate.getFullYear();

      objectValue['userIds'] = element.New_User_Referral_Id;
      objectValue['tx_Hash'] = element.tx_Hash;
      objectValue['create_At'] = formattedDate; // Include the formatted date
      objectValue['userLevel'] = "Level-1";
      objectValue['amount'] = 7.5;
      objectValue['walletAddress'] = element.New_User_Registered_Address;

      assignValue.push(objectValue);
    });


    setTransactionDetailsInTable(prevState => [...assignValue, ...prevState]);
  };



  const dmlmXcoreBuyNextLevelTransactionDetails = async (ids, level) => {
    if (ids.length > 0) {
      let idsArray = [].concat(...ids);
      let uniqueIdsArray = [...new Set(idsArray)]; // Convert to Set to remove duplicates, then back to array

      const showTheTransactionDetails = await getTheBuyNextLevelDmlmXcoreCompletedTransactions(uniqueIdsArray, level);

      const assignValue = [];

      const seenIds = new Set();

      if (Array.isArray(showTheTransactionDetails.result)) {
        const promises = showTheTransactionDetails.result.map(async (element) => {
          if (seenIds.has(element._id)) {
            return;
          }
          seenIds.add(element._id); // Add ID to seenIds set

          const objectValue = {};
          let purchasedAmount = web3js.utils.fromWei(element.Level_Purchased_Amount, "ether");
          var createdDate = new Date(element.Create_at);
          var formattedDate = createdDate.getDate() + " " +
            createdDate.toLocaleString('default', { month: 'short' }) + " " +
            createdDate.getFullYear();
          objectValue['userIds'] = element.Owner_MLM_ID;
          objectValue['tx_Hash'] = element.Next_Level_Txhah;
          objectValue['create_At'] = formattedDate;
          objectValue['userLevel'] = element.Buy_Level_Status;
          objectValue['walletAddress'] = element.Owner_From_Address;
          objectValue['amount'] = parseFloat(purchasedAmount) * 0.5;

          assignValue.push(objectValue);
        });


        await Promise.all(promises);

      } else {
        // console.error('showTheTransactionDetails.result is not an array:', showTheTransactionDetails.result);
      }


      setTransactionDetailsInTable(prevState => [...prevState, ...assignValue]);
      // return;
    }
  };


  useEffect(() => {
    checkNextNewLevelStatus();

  }, [checkLevelMatching, connectedWallet]);

  const eventAccountWatch = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });

      if (accounts.length == 1) {
        setConnectedWallet(accounts[0]);
      } else if (accounts.length > 1) {
        if (!toast.isActive(toastCheck.current)) {
          // toastCheck.current = toast.warning(ToastApi.ConnectAnyOneAccount);
          console.log("connect any one account");
        }
      } else if (accounts.length == 0) {
        if (!toast.isActive(toastCheck.current)) {
          // toastCheck.current = toast.warning(ToastApi.ConnectYourWallet)
          console.log("connect your wallet");
        }
      }
    }
    else {
      console.log("Metamask not Install");
    }

  }


  useEffect(() => {

    setIsCopied(false);
    getListOfRegisterData();

    getListOfRegisterData1()
  }, [connectedWallet, referredByUserId]);



  const checkLevelStatus = async () => {
    const levelStatuses = [];
    try {

      for (let i = 1; i <= 10; i++) {

        let levelStatus = await DemoCorePlanContract.methods.usercompletelevel(referralAddress, i).call();
        levelStatuses.push(levelStatus);
      }
      
      return levelStatuses;
    } catch (error) {
      // console.error("Error checking level status:", error);
      return [];
    }
  }


  useEffect(() => {
    checkLevelStatus().then(levelStatuses => {

      const trueCount = levelStatuses.filter(status => status).length;
      const falseCount = 10 - trueCount;


      const activePrograms = Array.from({ length: trueCount }, (_, index) => (
        <div key={index} className="dashboard-user-programs dashboard-user-programs-active"></div>
      ));

      const nonActivePrograms = Array.from({ length: falseCount }, (_, index) => (
        <div key={trueCount + index} className="dashboard-user-programs"></div>
      ));

      const programElements = [...activePrograms, ...nonActivePrograms];
      setListFind(programElements);
    }).catch(error => {
      console.error("Error checking level status:", error);
    });

  }, [referralAddress]);
  
  const switchNetwork = async () => {
    try {
      // Switch network
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.DCXCHAIN_HEX_ID }] // Replace '0x1' with the chain ID you want to switch to
      });
      // toast.success(ToastApi.AddNetworkSuccessToast);
    } catch (error) {

    }

  }

  const invitedIdRedirect = async (invitedId) => {
    try {

      window.location.href = config.WINDOW_LOCATION_DASHBOARD_PAGE + '/' + `${invitedId}`;
    } catch (error) {

    }
  };



  const getallpartner = async () => {
    try {
      // const result =await axios.post(`${config.BACKEND_URL}v1/getallpartners`,{locateUserId})
      const result = await getallpartnerdetails({ locateUserId });

      if (result) {
        setPartnetlen(result.resultPartner)
        setTeamlen(result.resultTeam)
      } else {
        setPartnetlen([])
        setTeamlen([])
      }


    } catch (error) {

    }
  }

  useEffect(() => {
    // switchNetwork();
    getallpartner();
    eventAccountWatch();
  }, []);



  return (
    <div className="App">
      {/* <ToastContainer/> */}
      <Topnav />
      <section className="dashboard-split-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
              <Sidenav userId={locateUserId} />
            </div>
            <div className="col-lg-10 dashboard-split-right-section">
              <div className="row p-2 g-4">
                <div class="dashboard-user-programs-glow-3"></div>
                <div className="col-lg-6">
                  <div className="d-flex flex-lg-row flex-column align-items-center">
                    <div className="me-lg-4">
                      <div className="d-flex flex-row align-items-end">
                        <img className="dashboard-user-profile" src={unknownuser}
                          alt="User-profile" />
                        <img className="dashboard-user-coin-logo" src={bnbcoin}
                          alt="coin-logo" />
                      </div>

                    </div>
                    <div>
                      <p className="mb-0 dashboard-text-2">ID {locateUserId}</p>
                      <p className="mb-1 dashboard-text-3">{sliceAddress(referralAddress)}
                        <button className="ms-2 bg-transparent border-0 copy" onClick={handleCopyClick}
                          disabled={isCopied}>
                          <FaCopy />
                        </button>
                      </p>
                      {referredByUserId > 0 ? (
                        <>
                          <span className="me-2 dashboard-text-4">invited {invitedDate} by</span>
                          <button className="mt-2 dashboard-button-1" onClick={() => { invitedIdRedirect(referredByUserId) }}>ID {referredByUserId}</button>
                        </>
                      ) : (
                        <>
                          {/* <span className=" dashboard-text-4">invited {invitedDate} by
                            <button className="mt-2 dashboard-button-1" onClick={() => { invitedIdRedirect(referredByUserId) }}>ID {referredByUserId}</button>
                          </span> */}
                        </>
                      )}


                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card dashboard-card-1">
                    <div className="dashboard-card-1-inner-1">
                      <p className="dashboard-text-4 mb-2">Personal link <span className="ms-1">
                        <AiFillQuestionCircle className="p-link mb-1" id="clickable" />
                        <Tooltip anchorSelect=".p-link" place="top" clickable>
                          <div className='per-link'>Invites your friends to PropelX DUSD and build your team by sharing your link.</div>
                        </Tooltip>
                      </span>
                      </p>
                      <p className="dashboard-text-5 mb-0">{pathNameOfCurrentLocation}
                        <button className="bg-transparent border-0 copy ms-2" style={{ width: "fit-content" }} onClick={getRegisterationLinkWithID} disabled={copyPathNameOfCurrentLocation}><FaCopy /></button>
                      </p>
                    </div>
                    <div className="dashboard-card-1-inner-2 d-flex flex-lg-row flex-column gap-2">
                      <div>
                        <p className="dashboard-text-4 mb-0">Not a member yet?
                          Sign up with this upline</p>
                      </div>
                      {/* <a href={registrationPageUrl} target="_blank">
                        <button className="dashboard-button-2">View</button>
                      </a> */}
                    </div>
                    <div className="dashboard-card-1-inner-3 d-flex flex-lg-row justify-content-between gap-2">
                      <div>
                        <p className="mb-0 dashboard-text-6">My xCore invite link</p>
                      </div>
                      <div className="ms-lg-auto">
                        <a href={registrationPageUrl} target="_blank">
                          <button className=" dashboard-button-1">View</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-2 g-4">
                <div className="col-lg-6">
                  <div className="row g-4">
                    <div className="col-lg-4">
                      <div className="card dashboard-card-2">
                        <p className="dashboard-text-4 ms-2">Partners</p>
                        {/* <p className="mb-2 dashboard-text-2 ms-2">{partnetlen.length}</p> */}
                        <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                          <div className="dashboard-text2">
                            <FaArrowUp className="me-1 arrow-up" />
                            {partnetlen.length}
                          </div>
                          <div className="ms-auto">
                            <img className="dashboard-partners-gif" src={finalgif}
                              alt="gif" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="card dashboard-card-2">
                        <p className="dashboard-text-4 ms-2">Team</p>
                        {/* <p className="mb-2 dashboard-text-2 ms-2">{teamlen.length}</p> */}
                        <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                          <div className="dashboard-text2">
                            <FaArrowUp className="me-1 arrow-up" />{teamlen.length}
                          </div>
                          <div className="ms-auto">
                            <img className="dashboard-partners-gif" src={finalgif}
                              alt="gif" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="card dashboard-card-2 ratio-card">
                        <p className="dashboard-text-4 ms-2">Ratio</p>
                        {/* {
                          transactionDetailsInTable.length === 0 ? (
                            <>
                              <p className="mb-2 dashboard-text-2 ms-2">0 DUSD</p>
                            </>
                          ) : (
                            transactionDetailsInTable.length > 0 && (() => {
                              let totalAmount = 0;
                              transactionDetailsInTable
                                .filter((item, index, self) =>
                                  self.findIndex(t => t.userIds === item.userIds && t.userLevel === item.userLevel) === index
                                )
                                .forEach(item => {
                                  // Check if item.amount is a valid number
                                  const amount = parseFloat(item.amount);
                                  if (!isNaN(amount)) {
                                    totalAmount += amount;
                                  }
                                });
                              let sum = totalAmount + totalAmount;
                              const ratio1 = (sum / returnEarnedPercentage) * 100;
                              const returnPercentageOfEarned = (returnEarnedPercentage / returnEarnedPercentage) * 100 + ratio1;

                              console.log('totalAmount:', returnEarnedPercentage);
                              return (
                                <>

                                  <p className="mb-2 dashboard-text-2 ms-2">{returnPercentageOfEarned} %</p>
                                  <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                                    <div className="dashboard-text-7">
                                      <FaArrowUp className="me-1" />{returnEarnedPercentage} %
                                    </div>
                                    <div className="ms-auto">
                                      <img className="dashboard-partners-gif" src={finalgif}
                                        alt="gif" />
                                    </div>
                                  </div>
                                </>

                              );
                            })()
                          )
                        } */}

                        {/* <p className="mb-2 dashboard-text-2 ms-2">{returnEarnedPercentage} %</p> */}
                        <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                          <div className="dashboard-text2">
                            <FaArrowUp className="me-1 arrow-up" />{returnEarnedPercentage}%
                          </div>
                          <div className="ms-auto">
                            <img className="dashboard-partners-gif" src={finalgif}
                              alt="gif" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card dashboard-card-2">
                        <p className="dashboard-text-4 ms-2 mb-4">Profits
                          <span className="ms-1">
                            <AiFillQuestionCircle className="profit-link mb-1" id="clickable" />
                            <Tooltip anchorSelect=".profit-link" place="top" clickable>
                              <div className='profit-link-one'>Profits are a combination of your XCore amount and the level income amount from your downline.</div>
                            </Tooltip>
                          </span>
                        </p>

                        <div className="d-flex flex-row align-items-center mx-2 mb-1">
                          {/* {
                            transactionDetailsInTable.length === 0 ? (
                              <div className="dashboard-text-7">
                                <p className="mb-0 dashboard-text-8">0 DUSD</p>
                              </div>
                            ) : (
                              transactionDetailsInTable.length > 0 && (() => {
                                let totalAmount = 0;
                                transactionDetailsInTable
                                  .filter((item, index, self) =>
                                    self.findIndex(t => t.userIds === item.userIds && t.userLevel === item.userLevel) === index
                                  )
                                  .forEach(item => {
                                    const amount = parseFloat(item.amount);
                                    if (!isNaN(amount)) {
                                      totalAmount += amount;
                                    }
                                  });
                                return ( */}

                          <div className="dashboard-text-7">
                            <p className="mb-0 dashboard-text-8">{totalProfitAmount} DUSD</p>
                          </div>


                          {/* );
                              })()
                            )
                          } */}

                          {/* <div className="ms-auto">
                            <p className="dashboard-text-7 mb-0"><FaArrowUp className="me-1" />0</p>
                          </div> */}
                        </div>
                        {/* <div className="d-flex flex-row align-items-center mx-2 mb-1">
                          <div className="dashboard-text-7">
                            <p className="mb-0 dashboard-text-8">0 DUSD</p>
                          </div>
                          <div className="ms-auto">
                            <p className="dashboard-text-7 mb-0"><FaArrowUp className="me-1" />0</p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-2 g-4 mt-1 justify-content-center justify-content-lg-start">
                <div className="d-flex flex-row align-items-center gap-3">
                  <span className="dashboard-text-2">PropelX Programs
                    <AiFillQuestionCircle className="mx-2 fs-6 propelx-program" /></span>
                  <Tooltip anchorSelect=".propelx-program" place="top" clickable>
                    <div className='platform-tt'>Overview of the current programs status and progress. Click preview on any of the programs for detailed view</div>
                  </Tooltip>
                </div>


                <div className="row row-cols-1 row-cols-md-2 g-4">
                  {/* <div className="col">
                    <div className="card dashboard-card-3 position-relative overflow-hidden h-100">
                      <div className="dashboard-user-programs-glow-1"></div>
                      <div className="card-body dashboard-card-3-inner-1">
                        <p className="text-end mb-0 opacity-50 dashboard-text-10">
                          <ImArrowRight2 />
                        </p>
                        <div className="d-flex flex-row">
                          <div>
                            <span className="dashboard-text-9">xXx / xGold</span>
                          </div>
                          <div className="ms-auto">
                            <span className="dashboard-text-9">357 740 DUSD</span>
                          </div>
                        </div>

                        <div className="row align-items-end mt-4 mb-3 g-4">
                          <div className="col-lg-6 ">
                            <div className="grid-dashboard-container">

                              <div className="dashboard-user-programs dashboard-user-programs-active">
                              </div>
                              <div className="dashboard-user-programs dashboard-user-programs-active">
                              </div>
                              <div className="dashboard-user-programs dashboard-user-programs-active">
                              </div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div>

                            </div>
                          </div>
                          <div className="col-lg-6 text-end">
                            <a className="" >
                              <button className="dashboard-preview-btn-2">Preview <ImArrowRight2 className="ms-1" />
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="col">
                    <div className="card dashboard-card-3 position-relative overflow-hidden h-100">
                      <div className="dashboard-user-programs-glow-1"></div>
                      <div className="card-body dashboard-card-3-inner-1">
                        <p className="text-end mb-0 opacity-50 dashboard-text-10">
                          <a className="" href={dmlmXxxxGoldPageLink}>
                            <ImArrowRight2 className="arrow-right" />
                          </a>
                        </p>
                        <div className="d-flex flex-row">
                          <div>
                            <span className="dashboard-text-9">xCore</span>
                          </div>
                          {/* {
                            transactionDetailsInTable.length === 0 ? (
                              <div className="ms-auto">
                                <span className="dashboard-text-9">0 DUSD</span>
                              </div>
                            ) : (
                              transactionDetailsInTable.length > 0 && (() => {
                                let totalAmount = 0;
                                transactionDetailsInTable
                                  .filter((item, index, self) =>
                                    self.findIndex(t => t.userIds === item.userIds && t.userLevel === item.userLevel) === index
                                  )
                                  .forEach(item => {
                                    const amount = parseFloat(item.amount);
                                    if (!isNaN(amount)) {
                                      totalAmount += amount;
                                    }
                                  });
                                console.log('totalAmount:', totalAmount);
                                return ( */}
                          <div className="ms-auto">

                            <span className="dashboard-text-9">{Number(xCoreAmountFromTheUser)} DUSD</span>
                          </div>
                          {/* );
                              })()
                            )
                          } */}


                        </div>

                        <div className="row mt-4 mb-3 g-4">
                          <div className="col-lg-6 ">
                            <div className="grid-dashboard-container">
                              {listFind}
                              {/* <div className="dashboard-user-programs dashboard-user-programs-active">
                              </div>
                              <div className="dashboard-user-programs dashboard-user-programs-active">
                              </div>
                              <div className="dashboard-user-programs dashboard-user-programs-active">
                              </div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div>
                              <div className="dashboard-user-programs"></div> */}
                            </div>
                          </div>
                          <div className="col-lg-6 text-lg-end">
                            <a className="" href={dmlmXxxxGoldPageLink} >
                              <button className="dashboard-preview-btn-2">Preview <ImArrowRight2 className="ms-1" />
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card dashboard-card-3 position-relative overflow-hidden h-100">
                      <div className="dashboard-user-programs-glow-1"></div>
                      <div className="card-body dashboard-card-3-inner-1 h-100">
                        <p className="text-end mb-0 opacity-50 dashboard-text-10">
                          <a className="" href={`${config.WINDOW_LOCATION_LEVEL_INCOME_PAGE}${locateUserId}`}>
                            <ImArrowRight2 className="arrow-right" />
                          </a>
                        </p>
                        <div className="d-flex flex-row mt-2 gap-3">
                          <div>
                            <span className="dashboard-text-9">Level Income</span>
                          </div>
                          {/* {transactionDetailsInTable.length === 0 ? (
                            <div className="ms-auto">
                              <span className="dashboard-text-9">0 DUSD</span>
                            </div>
                          ) : (

                            transactionDetailsInTable.length > 0 &&
                            (() => {
                              let totalAmount = 0;
                              transactionDetailsInTable
                                .filter((item, index, self) =>
                                  self.findIndex(t => t.userIds === item.userIds && t.userLevel === item.userLevel) === index
                                )
                                .forEach(item => {
                                  const amount = parseFloat(item.amount);
                                  if (!isNaN(amount)) {
                                    totalAmount += amount;
                                  }

                                });
                              return ( */}
                          <div className="ms-auto">
                            <span className="dashboard-text-9">{Number(levelIncomeAmountFromTheUser)} DUSD</span>
                          </div>

                          {/* );
                            })()

                          )} */}

                        </div>

                        <div className="row align-items-end mt-4 mb-3">
                          <div className="col-lg-12 mt-2">
                            <a className="" href={`${config.WINDOW_LOCATION_LEVEL_INCOME_PAGE}${locateUserId}`}>
                              <button className="dashboard-preview-btn-2 mt-1">Preview<ImArrowRight2 className="ms-1" />
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card dashboard-card-3 position-relative overflow-hidden h-100">
                      <div className="dashboard-user-programs-glow-1"></div>
                      <div className="card-body dashboard-card-3-inner-1 h-100">
                        <p className="text-end mb-0 opacity-50 dashboard-text-10">
                          <a className="" href={"/rankincome/" + `${locateUserId}`}><ImArrowRight2 className="ms-1 arrow-right" />
                          </a>
                        </p>
                        <div className="d-flex flex-row mt-2">
                          <div>
                            <span className="dashboard-text-9">Rank Income</span>
                          </div>
                          {/* RewardAmount */}

                          {/* {
                            RewardAmount.length === 0 ? (
                              <div className="ms-auto">
                                <span className="dashboard-text-9">0 DUSD</span>
                              </div>
                            ) : (
                              (() => {
                                RewardAmount.forEach(item => {
                                  let totalAmount = 0;
                                  const amount = parseFloat(item.rank_income_reward);
                                  if (!isNaN(amount)) {
                                    totalAmount += amount;
                                  }
                                  console.log('item.rank_income---', totalAmount);

                                  return ( */}

                          <div className="ms-auto">
                            <span className="dashboard-text-9">
                              {totalRankRewardAmount.toFixed(2)} DUSD</span>
                          </div>

                          {/* )


                                })
                              })()
                            )} */}
                          {/* <div className="ms-auto">
                            <span className="dashboard-text-9">0 DUSD</span>
                          </div> */}
                        </div>

                        <div className="row align-items-end mt-4 mb-3">
                          <div className="col-lg-12 mt-2">
                            <a className="" href={"/rankincome/" + `${locateUserId}`}>
                              <button className="dashboard-preview-btn-2 mt-1">Preview <ImArrowRight2 className="ms-1" />
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col">
                    <div className="card dashboard-card-3 position-relative overflow-hidden h-100">
                      <div className="dashboard-user-programs-glow-1"></div>
                      <div className="card-body dashboard-card-3-inner-1 h-100">
                        <p className="text-end mb-0 opacity-50 dashboard-text-10"><ImArrowRight2 />
                        </p>
                        <div className="d-flex flex-row mt-2">
                          <div>
                            <span className="dashboard-text-9">Withdraw</span>
                          </div>
                          <div className="ms-auto">
                            <span className="dashboard-text-9">357 740 DUSD</span>
                          </div>
                        </div>

                        <div className="row align-items-end mt-4 mb-3">
                          <div className="col-lg-12 mt-2">
                            <a className="" href="/withdraw">
                              <button className="dashboard-preview-btn-2 mt-1">Preview<ImArrowRight2 className="ms-1" />
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="row p-2 g-4 mt-1 justify-content-center justify-content-lg-start">
                <div className="d-flex flex-row align-items-center gap-1">
                  <span className="dashboard-text-2">Platform recent activity</span>
                  <span className="ms-1 ">
                    <AiFillQuestionCircle className="fs-6 platform-recent1" id="clickable" />
                    <Tooltip anchorSelect=".platform-recent1" place="top" clickable>
                      <div className='platform-tt'>PropelX DUSD real-time global events with links to transaction in blockchain</div>
                    </Tooltip>
                  </span>
                </div>
                <div className="row g-4">
                  <div className="col-lg-8 p-0 p-lg-1">
                    <div className="card card-one dashboard-card-4">

                      {registerDataList.length > 0 ?
                        registerDataList.slice(0, visibleItems).map((item, index) => {
                          return (
                            <>
                              <div
                                className="d-flex flex-lg-row align-items-md-center align-items-baseline dashboard-card-4-inner-1 gap-2">
                                <div>
                                  <i className="fa-solid fa-wallet dashboard-activity-2"></i>
                                </div>
                                <div className="d-flex flex-row align-items-center gap-lg-2 flex-wrap">

                                  <div>
                                    <button className="dashboard-button-1" onClick={() => { invitedIdRedirect(item.newUserReferralId) }} >ID {item.newUserReferralId}</button>
                                  </div>
                                  <div>
                                    <p className="mb-0 recent-dusd">+
                                      15 DUSD
                                      <span className="dashboard-text-4"> in</span>
                                      <span style={{ color: "#f0b90b" }}> xCore</span>
                                    </p>
                                  </div>
                                </div>
                                <div className="ms-auto">
                                  <span className="dashboard-text-4">
                                    <a href={`${config.D_CHAIN_EXPLORER_TXHASH_URL + item.txHash}`} target='_blank'>
                                      <PiArrowSquareOutBold className="me-1" />{item.dateTime}
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </>
                          )
                        })
                        : (
                          <>
                            <div
                              className="d-flex flex-lg-row flex-column align-items-center dashboard-card-4-inner-1 gap-3">
                              <span className="dashboard-text-2">Data Not Found</span>
                            </div>
                          </>
                        )}



                      {registerDataList.length > config.REGISTER_HISTORY_SEE_MORE_LESS && (

                        <button
                          className="btn dashboard-activity-seemore-btn mt-2" type="button"
                          onClick={handleSeeMoreClick}
                        >
                          <IoIosArrowDown className="fs-5" /> {'See More'}
                        </button>

                      )}

                      {/* {registerDataList.length > config.REGISTER_HISTORY_SEE_MORE_LESS && (

                        <button
                          className="btn dashboard-activity-seemore-btn mt-2" type="button"
                          onClick={handleSeeLessClick}
                        >
                          <IoIosArrowUp className="fs-5" /> {'See Less'}
                        </button>

                      )} */}

                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card dashboard-card-4">
                      <p className="dashboard-text-4 mb-2">Members total <span className="">
                        <AiFillQuestionCircle className=" mx-1 mb-1 member-total1" id='clickable' />
                        <Tooltip anchorSelect=".member-total1" place="top" clickable>
                          <div className='platform-tt'>Total number of members in PropelX DUSD</div>
                        </Tooltip>
                      </span>
                      </p>
                      <p className="mb-1"><span className="dashboard-text-9">{registerDataList.length}</span></p>
                      {/* <p className="mb-2" style={{ color: "#2BEA4A" }}><FaArrowUp className="" /> 2 451
                      </p> */}
                    </div>

                    <div className="card dashboard-card-4 mt-4">
                      <p className="dashboard-text-4 mb-2">Members received <span className="">
                        <AiFillQuestionCircle className=" mx-1 member-receive1" id='clickable' />
                        <Tooltip anchorSelect=".member-receive1" place="top" clickable>
                          <div className='platform-tt'>Total amount received by all joining members of PropelX</div>
                        </Tooltip>
                      </span>
                      </p>
                      <p className="mb-1"><span className="dashboard-text-9">{registerDataList.length * 15} DUSD</span></p>
                      {/* <p className="mb-2" style={{ color: "#2BEA4A" }}>+
                        51 357.7 DUSD</p> */}
                      <div style={{ borderTop: "1px solid rgba(27, 159, 254, 0.048)" }} className="pt-4">
                        {/* <p className="mb-1"><span className="dashboard-text-9">11 779.626 BNB</span></p>
                        <p className="mb-2" style={{ color: "#2BEA4A" }}>+ 
                          34.634 BNB</p>*/}
                      </div>

                    </div>


                    <div className="card dashboard-card-4 mt-4">
                      <p className="dashboard-text-4 mb-2">PropelX DUSD Contracts</p>
                      <p className="mb-1"><span className="dashboard-text-9">{contractFunds} DUSD</span>
                      </p>
                      {/* <p className="mb-2" style={{ color: "#2BEA4A" }}>+
                        51 357.7 DUSD</p> */}

                      <div style={{ borderTop: "1px solid rgba(27, 159, 254, 0.048)" }}
                        className="d-flex flex-row py-2 align-items-center">
                        <div>
                          <p className="dashboard-text-4 mb-0">xCore </p>
                        </div>
                        <div className="ms-auto d-flex align-items-center gap-2 dchain-dusd" style={{ fontSize: "13px" }}>
                          <span>
                            <a href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.DEMO_CORE_PLAN_CONTRACT_ADDRESS}`} target='_blank'>
                              {sliceAddress(config.DEMO_CORE_PLAN_CONTRACT_ADDRESS)}
                            </a>
                          </span>
                          <button className="border-0 bg-transparent" onClick={handleXcoreContractAddressCopyClick} disabled={isXcoreContractAddressCopied}>
                            <FaCopy className="dchain-dusd" />
                          </button>
                          <a href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.DEMO_CORE_PLAN_CONTRACT_ADDRESS}`} target='_blank'>
                            <button className="border-0 bg-transparent"><i
                              className="fa-solid fa-link dchain-dusd"></i></button>
                          </a>
                        </div>
                      </div>
                      <div style={{ borderTop: "1px solid rgba(27, 159, 254, 0.048)" }}
                        className="d-flex flex-row py-2 align-items-center">
                        <div>
                          <p className="dashboard-text-4 mb-0">Level Income</p>
                        </div>
                        <div className="ms-auto d-flex align-items-center gap-2 dchain-dusd" style={{ fontSize: "13px" }}>
                          <span className="dchain-dusd">
                            <a href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.PLAN_1_CONTRACT_ADDRESS}`} target='_blank'>
                              {sliceAddress(config.PLAN_1_CONTRACT_ADDRESS)}
                            </a>
                          </span>
                          <button className="border-0 bg-transparent dchain-dusd" onClick={handleLevelIncomeContractAddressCopyClick} disabled={isLevelIncomeContractAddressCopied} ><FaCopy /></button>
                          <a href={`${config.D_CHAIN_EXPLORER_ADDRESS_URL + config.PLAN_1_CONTRACT_ADDRESS}`} target='_blank'>
                            <button className="border-0 bg-transparent"><i
                              className="fa-solid fa-link dchain-dusd"></i></button>
                          </a>
                        </div>
                      </div>





                      {/* <p className="dashboard-text-4 mb-2 mt-5">Transactions made </p>
                      <p className="mb-1"><span className="dashboard-text-9">5 148 145</span></p>
                      <p className="mb-2" style={{ color: "#2BEA4A" }}>+ 5 424</p>
                      <div style={{ borderTop: "1px solid #333333;" }} className="pt-2">
                        <p className="dashboard-text-4 mb-2">Turnover, DUSD </p>
                        <p className="mb-1"><span className="dashboard-text-9">282 075 504.76</span></p>
                        <p className="mb-2" style={{ color: "#2BEA4A" }}>+ 102 715.4</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
