import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,

} from "react-router-dom";
import "./Assets/Css/Style.css";
import "./Assets/Css/Home.css";
import "./Assets/Css/dashboard-style.css";
import Home from "./Home.js";
import Login from "./Pages/Login.js";
import Registration from "./Pages/Registration.js";
import Dashboard from "./Dashboard-pages/Dashboard";
import Levelincome from "./Dashboard-pages/Level-income";
import Links from "./Dashboard-pages/Links";
import Calculator from "./Dashboard-pages/Calculator.js";
import Dmlmgoldinner from "./Dashboard-pages/Dmlm-gold-inner.js";
import Instructions from "./Dashboard-pages/Instructions.js";
import Dmlmxcoreinner from "./Dashboard-pages/Dmlm-xcore-inner.js";
import Dmlmxcore from "./Dashboard-pages/Dmlm-xcore.js";
import Dmlmxxxinner from "./Dashboard-pages/Dmlm-xxx-inner.js";
import Dmlmxxxxgold from "./Dashboard-pages/Dmlm-xxx-xgold.js";
import Partners from "./Dashboard-pages/Partners";
import Rankincome from "./Dashboard-pages/Rank-income";
import Stats from "./Dashboard-pages/Stats";
import Withdraw from "./Dashboard-pages/Withdraw";
import Error from "./Pages/Error.js";
import Footer from "./Pages/Footer.js";
import Information from "./Pages/Information.js";
import InstructionInfo from "./Pages/information/InstructionInfo.js";
import CalculatorInfo from "./Pages/information/CalculatorInfo.js";
import Careerform from "./Dashboard-pages/Separate/Careerform.js";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="login" element={<Login />} />
          <Route exact path="registration" element={<Registration />} />
          <Route exact path="registration/:referralId" element={<Registration />} />
          <Route exact path="dashboard/:id" element={<Dashboard />} />
          <Route exact path="/levelincome/:id" element={<Levelincome />} />
          <Route exact path="/links" element={<Links />} />
          <Route exact path="/calculator/:id" element={<Calculator />} />
          <Route exact path="/dmlmgoldinner" element={<Dmlmgoldinner />} />
          <Route exact path="/instructions/:id" element={<Instructions />} />
          <Route exact path="/PropelXxcoreinner" element={<Dmlmxcoreinner />} />
          <Route exact path="/PropelXxcore/:id" element={<Dmlmxcore />} />
          <Route exact path="/dmlmxxxinner" element={<Dmlmxxxinner />} />
          <Route exact path="/dmlmxxxxgold" element={<Dmlmxxxxgold />} />
          <Route exact path="/partners" element={<Partners />} />
          <Route exact path="/rankincome/:id" element={<Rankincome />} />
          <Route exact path="/stats" element={<Stats />} />
          <Route exact path="/withdraw" element={<Withdraw />} />
          <Route exact path="/footer" element={<Footer />} />
          <Route exact path="/information" element={<Information />} />
          <Route exact path="/information" element={<Information />} />
          <Route exact path="/instructioninfo" element={<InstructionInfo />} />
          <Route exact path="/calculatorinfo" element={<CalculatorInfo />} />
          <Route exact path="/careerform" element={<Careerform />} />
          <Route path="*" element={<Error />} />
        </Routes>


      </Router >
    </div>
  );
}

export default App;
