import React, { useState, useEffect } from "react";

import { useNavigate } from 'react-router-dom';

import config from '../../config/apiConfig';
import DarkLight from '../../Dashboard-pages/Separate/Dark-Light';
import black from "../../Assets/images/Asset 2.png";
import white from "../../Assets/images/Asset 1.png";
import { LiaShareSquare } from "react-icons/lia";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastApi from '../../config/toastApi';
import Window_Ethereum_Config_Api from '../../config/windowEthereumConfig';
import DUSDtokenAbi from '../../ABI/DUSDtokenAbi.json';
import Plan1Abi from '../../ABI/Plan1Abi.json';
import DemoCorePlanAbi from '../../ABI/DemoCorePlan.json';
import Web3 from 'web3';
import InstructionInfo from "./InstructionInfo";



function NavbarInfo() {

  const [connectedWallet, setConnectedWallet] = useState(null);
  const [isNetworkToastShown, setIsNetworkToastShown] = useState(false);
  const toastCheck = React.useRef(null);
  const [isloading, setIsloading] = useState(false);
  const [open, setOpen] = useState(false);

  // console.log("information", information);
  var web3js = '';

  if (window.ethereum) {
    web3js = new Web3(window.ethereum);
    // web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  } else {
    web3js = new Web3(new Web3.providers.HttpProvider(config.YOUR_NODE_RPC_URL));
  }
  const DemoCorePlanContract = new web3js.eth.Contract(DemoCorePlanAbi, config.DEMO_CORE_PLAN_CONTRACT_ADDRESS);
  const DUSD_Contract_address = new web3js.eth.Contract(DUSDtokenAbi, config.DUSD_TOKEN_ADDRESS);



  const handleNetworkChange = async (chainId) => {
    try {
      if (chainId === config.DCXCHAIN_HEX_ID) {
        await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
        checkConnectedWallet();
        setIsNetworkToastShown(false); // Reset the flag when the network is correct
      }
    } catch (error) {
      console.error('Error handling network change:', error);
    }
  };

  const addCustomNetwork = async () => {
    try {
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.DCXCHAIN_HEX_ID }]
      });
      toast.success(ToastApi.AddNetworkSuccessToast);
    } catch (switchError) {
      if (isMobileDevice()) {
        if (switchError.data.originalError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.DCXCHAIN_HEX_ID,
                  chainName: config.DCX_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.DCX_NETWORK_NAME,
                    symbol: config.DCX_SYMBOL,
                    decimals: config.DCX_DECIMAL,
                  },
                  rpcUrls: [config.DCXRPC_URL],
                  blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                },
              ],
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }
      else {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: Window_Ethereum_Config_Api.wallet_addEthereumChain,
              params: [
                {
                  chainId: config.DCXCHAIN_HEX_ID,
                  chainName: config.DCX_NETWORK_NAME,
                  nativeCurrency: {
                    name: config.DCX_NETWORK_NAME,
                    symbol: config.DCX_SYMBOL,
                    decimals: config.DCX_DECIMAL,
                  },
                  rpcUrls: [config.DCXRPC_URL],
                  blockExplorerUrls: [config.DCX_BLOCKEXPLORER],
                },
              ],
            });
            toast.success(ToastApi.AddNetworkSuccessToast);
          } catch (addError) {
            console.log("error", addError);
          }
        }
      }
    }
  };

  const checkConnectedWallet = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_accounts });
        setConnectedWallet(accounts?.[0] || null);
      }
    } catch (error) {
      console.error('Error checking connected wallet:', error);
    }
  };

  const connectWallet = async () => {
    try {

      if (window.ethereum) {
        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });

        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        } else {
          const acc = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_requestAccounts });
          checkConnectedWallet();
        }
      } else {
        toast.warn(ToastApi.PleaseInstallMetamask)
        setTimeout(() => {
          handleInstallMetaMask('https://metamask.io/download.html')
        }, 1000);

      }
    } catch (error) {
      toast.error(ToastApi.WalletConnectionError);
    }
  };

  const handleInstallMetaMask = async (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.focus()
    }
  };

  const sliceAddress = (address, startLength = 6, endLength = 6) => {
    try {
      if (!address) return '';

      const prefix = address.slice(0, startLength);
      const suffix = address.slice(-endLength);

      return `${prefix}...${suffix}`;
    } catch (error) {

    }

  };

  const switchNetwork = async () => {
    try {
      // Switch network
      await window.ethereum.request({
        method: Window_Ethereum_Config_Api.wallet_switchEthereumChain,
        params: [{ chainId: config.DCXCHAIN_HEX_ID }] // Replace '0x1' with the chain ID you want to switch to
      });
    } catch (error) {

    }
  }

  function isMobileDevice() {
    let check = false;
    (function (a) {
      if (
        "ontouchstart" in window ||
        "onmsgesturechange" in window ||
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
  }

  const handleDesktopConnectClick = () => {
    try {
      connectWallet()
    } catch (error) {
      console.log('error', error)
    }
  }

  const mobilewalletConnect = async () => {
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
      try {
        const chainId = await window.ethereum.request({ method: Window_Ethereum_Config_Api.eth_chainId });
        if (chainId !== config.DCXCHAIN_HEX_ID) {
          await addCustomNetwork();
        } else {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          if (accounts && accounts.length > 0) {
            const address = accounts[0];
            localStorage.setItem("address", accounts[0]);
            setConnectedWallet(accounts[0]);

          } else {
            console.log("Account request failed");
          }
          window.location.reload()
        }
      } catch (error) {
        console.error("Failed to request accounts:", error);
      }
    }
    // else {
    //     toast.warn("Please install metamask");
    //     openInNewTab(
    //       `https://metamask.io/download/`
    //     );
    //   }
  };

  const handleMobileConnectClick = async () => {

    try {
      if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
        mobilewalletConnect();
        return;
      }
      else {
        // const confirmed = window.confirm(
        //   "Connect your MetaMask wallet to this app?"
        // );

        let dappUrl = `${config.FRONTEND_URL}`;
        let metamaskAppDeepLink = `https://metamask.app.link/dapp/${dappUrl}`;
        window.location.href = metamaskAppDeepLink;

      }

    } catch (error) {
      console.log("error", error);
    }

  };

  const handleClose = () => {
    setOpen(false);
    setIsloading(false)
  };

  return (
    <div className="navbarinfo">
      <div className='register-section-one fixed-top'>
        <nav class="navbar navbar-expand-lg">
          <div class="container">
            <a class="navbar-brand pb-lg-0 pt-lg-2" href="/">
              <img className="banner-img1 black-logo" src={black} alt="logo" />
              <img className="banner-img1 white-logo" src={white} alt="logo" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarSupportedContent">

              <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-lg-center">
                <li><DarkLight /></li>
                <li className='ms-lg-3 mt-2 mt-lg-0'>
                  {/* <form class="d-flex " role="search"> */}

                  {connectedWallet ? (
                    <div class="btn33 fs-6"> {sliceAddress(connectedWallet)}</div>
                  ) : (
                    <button class="btn33 fs-6" onClick={() => {
                      isMobileDevice() ? handleMobileConnectClick() : handleDesktopConnectClick()
                    }}>
                      Connect Wallet
                    </button>
                  )}
                </li>



                <li>
                  <a href='/'>
                    <button class="btn44 mx-lg-3 mt-2 mt-lg-0" ><LiaShareSquare className="opacity-50 text-center fs-5" /></button>
                  </a>
                </li>






                {/* </form> */}
              </ul>

              <ul className="navbar-nav ms-auto d-lg-none d-block mt-3">
                <div className="col-lg-2 dashboard-split-left-section">
                  <ul className="navbar-nav">
                    <li className="sidenavbar-nav-link-2">
                      <div className="accordion" id="accordionExample2">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button accordion-button-2"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwoUniqueId"
                              aria-expanded="true"
                              aria-controls="collapseTwoUniqueId"
                            >
                              <div className="d-flex flex-row align-items-center">
                                <div className="sidenav-icon-1">
                                  <i className="fa-solid fa-book-open"></i>
                                </div>
                                <div>
                                  <span className="sidenav-text-1">
                                    Information
                                  </span>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseTwoUniqueId"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              <ul className="navbar-nav">
                                <li className="sidenavbar-nav-link-2">
                                  <a className="" href="/instructioninfo">
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <i className="fa-solid fa-shield-halved"></i>
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Instruction
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li className="sidenavbar-nav-link-2">
                                  <a className="" href="/calculatorinfo">
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <i className="fa-solid fa-calculator"></i>
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Calculator
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </ul>




            </div>
          </div>
        </nav>
      </div>

    </div>
  )
}

export default NavbarInfo